/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';

// Calculate 
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	//document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Same height
if (document.querySelectorAll('.content-block.is-columns-block .title').length) { 
	new ResponsiveAutoHeight('.content-block.is-columns-block .title');
}

if (document.querySelectorAll('.highlighted-slider-item .usp-title').length) {
	new ResponsiveAutoHeight('.highlighted-slider-item .usp-title');
}

if (document.querySelectorAll('.same-height').length) {
	new ResponsiveAutoHeight('.same-height');
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav-toggler'), navigation = document.querySelector('.nav.is-side-nav'), navigationContainer = document.querySelector('.nav.is-side-nav .nav-container');
if (navigationToggler && navigation && navigationContainer) {
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
		} else {
			focusLock.off(navigationContainer);
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Headeroom
import Headroom from 'headroom.js';
var headroom = new Headroom(document.body);
headroom.init();

// Swiper
var sliders = document.querySelectorAll('.slider');
if (sliders.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		sliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				speed: 1000,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				},
				pagination: {
					el: el.querySelector('.swiper-custom-pagination'),
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (el.querySelectorAll('.swiper-slide')[index].getAttribute('data-name')) + '</span>';
					}
				}
			});
		});
	})();
}

//Highlighte swiper
var highlighedSlider = document.querySelectorAll('.highlighted-slider, .category-slider, .speaker-slider');
if (highlighedSlider.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		highlighedSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = el.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			el.appendChild(swiperButtonPrev);
			el.appendChild(swiperButtonNext);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			var slidesPerView = 3,
				spaceBetween = 0,
				loop = false,
				autoplay = false,
				breakpoints = { 768: { slidesPerView: 1 }, 1024: { slidesPerView: 2 } };

			if (el.classList.contains("speaker-slider")) {
				spaceBetween = 48;
			}

			if (el.classList.contains("category-slider")) {
				slidesPerView = 4;
				loop = true;
				autoplay = { delay: 2500, disableOnInteraction: true };
				breakpoints = { 768: { slidesPerView: 1 }, 1024: { slidesPerView: 2 }, 1280: { slidesPerView: 3 } };
			}

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: slidesPerView,
				breakpoints: breakpoints,
				spaceBetween: spaceBetween,
				simulateTouch: false,
				speed: 1000,
				loop: loop,
				autoplay: autoplay,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// Random fader
var brandsFader = document.querySelectorAll('.brands-fader, .content-block.is-customer-block');
if (brandsFader.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		brandsFader.forEach(el => {
			var slides = el.querySelectorAll('.swiper-slide'),
				faderContainers = el.querySelectorAll('.column'),
				faderContainerCount = faderContainers.length;

			var faderContainerIndexArray = [];
			function getRandomFaderContainer() {

				// Check if array is empty and then fill it again
				if (faderContainerIndexArray.length === 0) {
					for (var i = 0; i < faderContainerCount; i++) {
						faderContainerIndexArray.push(i);
						shuffle(faderContainerIndexArray);
					}
				}

				return faderContainers[faderContainerIndexArray.pop()];
			}

			function getRandomFaderContainerThatIsDisplayed() {

				var faderContainer = getRandomFaderContainer();
				if (getComputedStyle(faderContainer, null).display === "none") {
					faderContainer = getRandomFaderContainerThatIsDisplayed();
				}
				return faderContainer;
			}
			
			var controlCenter = new Swiper(el.querySelector('.fader-control-center'), {
				autoplay: true,
				speed: 1000,
				on: {
					slideChange: function () {
						var randomFaderContainer = getRandomFaderContainerThatIsDisplayed(),
							slideToShow = slides[controlCenter.realIndex].querySelector('figure'),
							slideToShowClose = slideToShow.cloneNode(true);

						slideToShowClose.classList.add('is-faded-image');
						randomFaderContainer.appendChild(slideToShowClose);
					}
				}
			});
		});
	})();
}

//Shuffle array
function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

// Close dropdown on click
document.querySelectorAll('dl.dropdown-list > dt').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add('clicked');
		el.parentElement.classList.toggle('open');
	});

	el.addEventListener('blur', () => {
		// Timeout is required for iOS
		setTimeout(() => {
			el.parentElement.classList.remove('clicked');
			el.parentElement.classList.remove('open');
		}, 0);
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			el.click();
			e.preventDefault();
		}
	});
});

// Close search-wrapper on backdrop click or escape key
const searchWrapper = document.querySelector('header .search-wrapper'),
	search = document.querySelector('header .search-wrapper form'),
	searchToggler = document.getElementById('search'),
	searchInput = document.getElementById('q');

if (searchToggler && searchWrapper && search) {
	var closeToggle = search.querySelector('.close');
	const toggle = checked => {
		searchToggler.checked = checked;
		searchWrapper.setAttribute('aria-expanded', checked);
		if (checked) {
			focusLock.on(search);
			// Focus close toggle on open
			if (closeToggle) {
				closeToggle.focus();
			}
		} else {
			focusLock.off(search);
		}
	};
	searchWrapper.addEventListener('click', e => {
		if (!search.contains(e.target)) {
			toggle(false);
		}
	});
	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && searchToggler.checked) {
			toggle(false);
		}
	});
	// Keep aria-expanded in-sync with checked state
	searchToggler.addEventListener('change', () => {
		toggle(searchToggler.checked);
	});
	toggle(searchToggler.checked);
}

// Focus search input
if (searchToggler && searchInput) {
	searchToggler.addEventListener('change', () => {
		if (searchToggler.checked) {
			// Add delay because the element isn't immediately visible
			setTimeout(function () { searchInput.focus(); }, 250);
		}
	});
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=lightbox]',
		download: false,
		thumbnail: false,
		fullScreen: false,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

loadLightGallery();

// Paged images
async function loadWaypoints() {
	const InfiniteScroll = (await import(/* webpackChunkName: "waypoints" */ 'infinite-scroll')).default;

	var albums = document.querySelectorAll('.is-album-block');
	albums.forEach(el => {
		var container = el.querySelector('.columns'),
			button = el.querySelector('.page-link'),
			pagingContainer = el.querySelector('.pagination');

		button.addEventListener('click', function (e) {

			var infScroll = new InfiniteScroll(el, {
				// options
				path: '.page-link',
				append: false,
				history: false
			});

			infScroll.on('load', function (response, path) {
				const delay = 40;
				var i = 0;

				response.querySelectorAll('.album-item').forEach(child => {
					child.classList.add('is-hidden');
					container.appendChild(child);
					setTimeout(function () { child.classList.remove('is-hidden'); }, delay * i);
					i++;
				});
			});

			infScroll.on('last', function (response, path) {
				pagingContainer.remove();
			});

			infScroll.loadNextPage();

			e.preventDefault();
		});
	});
}
loadWaypoints();

// Modal
var speakerModals = document.querySelectorAll('.speaker.is-modal');
if (speakerModals.length) {

	speakerModals.forEach(el => {

		el.addEventListener('click', e => {
			var modalId = el.dataset.modalId;

			if (modalId) {
				var modal = document.getElementById(modalId);
				modal.classList.add('is-visible');

				var modalClose = modal.querySelector('.modal-close');
				if (modalClose) {
					modalClose.addEventListener('click', e => {
						modal.classList.remove('is-visible');
					});
				}

				var modalContent = modal.querySelector('.modal-content');
				if (modalContent) {
					modalContent.addEventListener('click', e => {
						e.stopPropagation();
					});
				}

				modal.addEventListener('click', e => {
					modal.classList.remove('is-visible');
				});
			}
		});
	});
}

// Jump to submitted form
document.querySelectorAll('.content-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (form) {
		if (!anchor) {
			anchor = el.querySelector('.form').getAttribute('id');
		}
		else {
			anchor = anchor.getAttribute('id');
		}

		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});
	}
});